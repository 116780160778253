import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of, tap } from 'rxjs';

import { Project } from '../interfaces/project';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {
  constructor(private http: HttpClient) { }

  getProjectById(projectId: string) {
    return this.http.get('/api/project/id/' + projectId);
  };

  getProjectByRaceAndCode(raceId: string, code: string) {
    return this.http.get('/api/project/race/' + raceId + '/code/' + code);
  };

  getProjectItemsForRace(raceId: string) {
    return this.http.get('/api/project/items/race/' + raceId);
  };

  getProjectLevelsForRace(raceId: string) {
    return this.http.get('/api/project/levels/race/' + raceId);
  };

  getOutfitProjectsForRace(raceId: string) {
    return this.http.get('/api/project/outfit/race/' + raceId);
  };

  updateProjectById(projectId: string, update: { [key: string]: any }) {
    return this.http.put<Project>('/api/project/' + projectId, update);
  };
}

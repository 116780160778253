import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Fleet } from '../interfaces/fleet';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class FleetService {

    constructor(
        private http: HttpClient,
    ) { }

    getFleetForId$(fleetId: string): Observable<Fleet> {
        return this.http.get<Fleet>('/api/fleet/id/' + fleetId);
    };

    getFleetsForRaceId$(raceId: string): Observable<Fleet[]> {
        return this.http.get<Fleet[]>('/api/fleet/race/' + raceId);
    };

    getActiveFleetsForRace$(raceId: string): Observable<Fleet[]> {
        return this.http.get<Fleet[]>('/api/fleet/race/' + raceId + '/active');
    };

    getFleetsForRaceAndStarSystem$(raceId: string, starSystemId: string): Observable<Fleet[]> {
        return this.http.get<Fleet[]>('/api/fleet/race/' + raceId + '/starsystem/' + starSystemId);
    };

    getFreighterOverflowFleetsForRace$(raceId: string): Observable<Fleet[]> {
        return this.http.get<Fleet[]>('/api/fleet/race/' + raceId + '/freighterOverflow');
    };

    saveNewFleet$(fleet: Fleet): Observable<Fleet> {
        return this.http.post<Fleet>('/api/fleet', fleet);
    };

    saveFleet$(fleet: Fleet): Observable<Fleet> {
        if (fleet._id != null) {
            return this.http.put<Fleet>('/api/fleet/' + fleet._id, fleet);
        } else {
            return this.saveNewFleet$(fleet);
        }
    };

    getNewFleet(campaignId: string, raceId: string, locationHex: string = '0101') {
        var newFleet = {
            campaignId: campaignId,
            raceId: raceId,
            fleetName: 'New Fleet',
            instanceIdentifier: 0,
            turnMode: 4,
            status: 'active',
            // starSystemId: starSystemId,
            locationHex: locationHex,
            heading: 0,
            speed: 0,
            screenDistance: 0,
            turnActivated: 0,
            turnDeactivated: 0
        };
        return newFleet;
    };
}

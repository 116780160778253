import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, map } from 'rxjs';

import { Race } from '../interfaces/race';
import { ShipClass } from '../interfaces/ship-class';

@Injectable({
  providedIn: 'root'
})
export class ShipClassService {
  constructor(
    private http: HttpClient,
  ) { };

  getShipClassForId$(shipClassId: string) {
    return this.http.get('/api/shipClass/id/' + shipClassId);
  };

  deleteShipClassForId$(shipClassId: string) {
    return this.http.delete('/api/shipClass/' + shipClassId);
  };

  getShipClassesForRaceWithCount$(race: Race) {
    return this.http.get<ShipClass[]>('/api/shipClass/race/' + race._id + '/withCount');
  };

  getShipClassesForRace$(race: Race) {
    return this.http.get<ShipClass[]>('/api/shipClass/race/' + race._id);
  };

  getFilteredShipClassesForRace$(race: Race, shipFilter: any): Observable<ShipClass[]> {
    return this.getShipClassesForRace$(race).pipe(
      map(shipClasses => {
        if (typeof shipFilter === 'function') {
          return shipClasses.filter(shipFilter);
        }
        return shipClasses;
      })
    );
  };

  getSharedShipClasses$(race: Race) {
    return this.http.get('/api/shipClass/shared');
  };

  saveShipClass$(shipClass: ShipClass): Observable<ShipClass> {
    if (shipClass._id != null) {
      return this.http.put<ShipClass>('/api/shipClass/' + shipClass._id, shipClass);
    } else {
      return this.saveNewShipClass$(shipClass);
    }
  };

  saveNewShipClass$(shipClass: ShipClass): Observable<ShipClass> {
    return this.http.post<ShipClass>('/api/shipClass', shipClass);
  };

  copyShipClass(shipClass: any, race: Race) {
    let newShipClass = Object.assign({}, shipClass);
    delete newShipClass._id;
    delete newShipClass.__v;
    newShipClass.campaignId = race.campaignId;
    newShipClass.raceId = race._id;
    newShipClass.inactive = 1;

    return newShipClass;
  };

  newShipClass(campaignId: string, raceId: string) {
    return {
      campaignId: campaignId,
      raceId: raceId,
      className: "New Class",
      hullClass: "",
      ssd: "",
      actualSize: 0,
      buildCost: 0,
      maintenanceCost: 0,
      inactive: 1,
    };
  }
}
